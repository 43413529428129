<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-15 16:38:12
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-18 18:45:09
 * @FilePath: /mediatom-web/src/viewsForManage/mediaDashboard/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="media-dashboard-container" :style="{'font-size': fontSize + 'px'}">
    <BoardList/>
    <Chart/>
  </div>
</template>

<script>
import autoFontSize from '@/mixins/autoFontSize'
import Chart from './models/Chart'
import BoardList from './models/BoardList'
export default {
  mixins: [autoFontSize],
  components: { Chart, BoardList },
  created () {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="less" scoped>
.media-dashboard-container{
  // min-width: 1280px;
  margin: calc(32em / 14);
  display: flex;
  flex-direction: column;
}
</style>
